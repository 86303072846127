import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../PackageDelivery.style";
import clsx from "clsx";
import PackageDeliveryRowProperties from "./PackageDeliveryRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import Currency from '../../../entities/Currency';
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import {useUserContext} from '../../../contexts/UserContext';
import ChooseContactLink from '../../../shared_components/Buttons/ChooseContactLink/ChooseContactLink';
import AllowAccess from '../../../shared_components/Access/AllowAccess/AllowAccess';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import Access from '../../../shared_components/Access/AllowAccess/Access';
import CommentsButton from '../../../shared_components/Buttons/CommentsButton/CommentsButton';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';
import PrintToDeliverButton from '../../../shared_components/Buttons/PrintToDeliverButton/PrintToDeliverButton';
import RemovePackageDeliveryButton
    from "../../../shared_components/Buttons/RemovePackageDeliveryButton/RemovePackageDeliveryButton";
import ShowPackageDeliveryAmount
    from "../../../shared_components/Info/ShowPackageDeliveryAmount/ShowPackageDeliveryAmount";
import DeliveryOrderType from "../../../model/DeliveryOrderType";
import ShowOrderStatus from "../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus";
import UpdateDeliveryOrderStatusButton
    from "../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton";
import ShowDeliveryPersonMulti from '../../../shared_components/Info/ShowDeliveryPersonMulti/ShowDeliveryPersonMulti';
import ViewInvoiceButton from "../../../shared_components/Buttons/ViewInvoiceButton/ViewInvoiceButton";
import ShowTextHorizontal from '../../../shared_components/Info/ShowTextHorizontal/ShowTextHorizontal';
import NewPackageDeliveryButton from '../../../shared_components/Buttons/NewPackageDeliveryButton/NewPackageDeliveryButton';
import Tag from '../../../ui_components/Tag/Tag';
import TagType from '../../../ui_components/Tag/TagType';
import ObservationsButton from '../../../shared_components/Buttons/ObservationsButton/ObservationsButton';
import ProductOrderStatus from '../../../entities/productOrder/ProductOrderStatus';
import ProductOrderStatusHistoryButton from '../../../shared_components/Buttons/ProductOrderStatusHistoryButton/ProductOrderStatusHistoryButton';
import ShowPackageDeliveryType from '../../../shared_components/Info/ShowPackageDeliveryType/ShowPackageDeliveryType';
import ServiceName from '../../../entities/ServiceName';

const PackageDeliveryRowMedium: React.FC<PackageDeliveryRowProperties> = ({
                                                                          packageDeliveryOrder,
                                                                          selectedDeliveryOrderIds,
                                                                          onPackageDeliveryOrderClick,
                                                                          onPackageDeliveryOrderSelect,
                                                                          onDeliveryPersonSelect,
                                                                          onContactSelect,
                                                                          onUpdate,
                                                                          filter,
                                                                              shippingId
                                                                      }) => {


    const classes = useStyles();
    const i18n = useI18nContext();
    const userStore = useUserContext();

    const onRowMoneyRemittanceClick = () => {
        onPackageDeliveryOrderClick(packageDeliveryOrder.id);
    }

    const onPackageDeliverySelect = () => {
        onPackageDeliveryOrderSelect(packageDeliveryOrder.id, packageDeliveryOrder.orderNumber);
    } 

    return (
        <div
            className={clsx(classes.money_info_container, userStore.isRootAgency() ? classes.money_info_container_grid_template_big : classes.money_info_container_grid_template_small)}>
            <div>
                <div className={classes.order_number_container}>
                    <Checkbox text={''} onChange={onPackageDeliverySelect} selected={selectedDeliveryOrderIds.findIndex(t => t === packageDeliveryOrder.id) >= 0}/>
                    <ShowOrderNumber orderNumber={packageDeliveryOrder.orderNumber} className={classes.order_number_text} textToHighlight={filter}/>
                </div>
                <div className={classes.order_number_container}>
                    {packageDeliveryOrder.shippingId ? (
                        <>
                            <ShowText text={i18n.translate("SHIPPING_NUMBER")} />
                            <ShowText text={packageDeliveryOrder.shippingId} textToHighlight={shippingId} />
                        </>
                    ) : (
                        <ShowText text={i18n.translate("NO_SHIPPING_ASSIGNED")} />
                    )}
                </div>
                <ShowDate label={i18n.translate("CREATED")} timestamp={packageDeliveryOrder.createdAt} className={classes.margin_top_4}/>
            </div>
            <div>
                <div className={classes.padding_right_5px}>
                    <ShowText text={packageDeliveryOrder.clientName} title={'Remitente'} textToHighlight={filter}/>
                    {packageDeliveryOrder.clientPhone && <ShowPhoneNumber value={packageDeliveryOrder.clientPhone} showFlag={false} textToHighlight={filter}/>}
                </div>
                <div className={classes.padding_right_5px}>
                    <ShowText text={packageDeliveryOrder.contactName} title={'Destinatario'} textToHighlight={filter}/>
                    {packageDeliveryOrder.contactPhone && <ShowPhoneNumber value={packageDeliveryOrder.contactPhone} showFlag={false} textToHighlight={filter}/> }
                    <AllowAccess agencyId={packageDeliveryOrder.agencyId} any={[Access.UPDATE_PACKAGE_DELIVERY_ORDER_CONTACT]}>
                        {packageDeliveryOrder.status !== 'DELIVERED' && packageDeliveryOrder.status !== 'PROCESSING'
                            && packageDeliveryOrder.status !== 'CANCELED' && packageDeliveryOrder.status !== 'NOT_DELIVERED' &&
                            <ChooseContactLink clientId={packageDeliveryOrder.clientId} onClick={onRowMoneyRemittanceClick} onSelect={onContactSelect}/>}
                </AllowAccess>
                </div>
            </div>
            <ShowText text={packageDeliveryOrder.address} title={packageDeliveryOrder.geo.name + '. ' + packageDeliveryOrder.geo.parentInfo}/>
            <div>
                {packageDeliveryOrder.itemsAndCategories.length>0 &&
                    <>
                        {packageDeliveryOrder.itemsAndCategories.map((categoryWithItems, i) => (
                            <ShowTextHorizontal text={`${categoryWithItems.weightInfo?.weight} lbs`} title={`${categoryWithItems.categoryInfo.name}`}/>
                        ))}
                        <ShowTextHorizontal text={`${packageDeliveryOrder.totalWeight} lbs`} title={'Pesaje total'} textToHighlight={filter}/>
                    </>
                }
                <ShowPackageDeliveryType deliveryType={packageDeliveryOrder.deliveryType.toString()}/>
                {userStore.hasAccess(Access.CREATE_EDIT_PACKAGE_DELIVERY_ORDER) &&
                  <NewPackageDeliveryButton type={ButtonType.PRIMARY} minimal={true} packageDeliveryId={packageDeliveryOrder.id} />
                }
            </div>            
            <ShowPackageDeliveryAmount
                valueToPay={packageDeliveryOrder.totalToPay}
                currencyToPay={Currency.DOLLAR}/>
            <ShowDeliveryPersonMulti
                deliveryOrderIds={[packageDeliveryOrder.id]}
                deliveryOrderNumbers={[packageDeliveryOrder.orderNumber]}
                currentDeliveryPersonInfo={packageDeliveryOrder.currentDeliveryPersonInfo}
                text={""}
                orderByGeosIdProximity={[packageDeliveryOrder.geo.id]}
                showActionLinks={packageDeliveryOrder.status !== 'DELIVERED' && packageDeliveryOrder.status !== 'CANCELED' && packageDeliveryOrder.status !== 'NOT_DELIVERED'}
                onClick={onRowMoneyRemittanceClick}
                onSelect={onDeliveryPersonSelect}
                onUpdate={onUpdate}
            />
            <div>
                <ShowText text={packageDeliveryOrder.agencyName} title={'Agencia'}/>
                <ShowText text={packageDeliveryOrder.userName} title={'Vendedor'} className={classes.margin_top_8}/>
            </div>
            <div className={classes.status_container}>
                {!packageDeliveryOrder.payed && <Tag text={"SIN PAGAR"} tagType={TagType.DANGER}/>}
                <ShowOrderStatus status={packageDeliveryOrder.status}/>
                {packageDeliveryOrder.status !== ProductOrderStatus.DELIVERED &&
                    packageDeliveryOrder.status !== ProductOrderStatus.CANCELED &&
                    <UpdateDeliveryOrderStatusButton onSave={onUpdate} productOrderId={packageDeliveryOrder.id} deliveryOrderType={DeliveryOrderType.PACKAGE_DELIVERY}/>}
                {packageDeliveryOrder.status === ProductOrderStatus.DELIVERED &&
                    <ProductOrderStatusHistoryButton
                        detailsLink
                        productOrderId={packageDeliveryOrder.id}
                    />}
            </div>
            <ExpandoMenu>
                <>
                    <ViewInvoiceButton
                        size={ButtonSize.SMALL}
                        productOrderId={packageDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <PrintToDeliverButton
                        size={ButtonSize.SMALL}
                        productOrderIds={[packageDeliveryOrder.id]}
                        service={ServiceName.PACKAGE_DELIVERY}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ProductOrderStatusHistoryButton
                        size={ButtonSize.SMALL}
                        productOrderId={packageDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ObservationsButton
                        size={ButtonSize.SMALL}
                        deliveryOrderId={packageDeliveryOrder.id}
                        deliveryOrderNumber={packageDeliveryOrder.orderNumber}
                        deliveryOrderObservations={packageDeliveryOrder.observations}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <AllowAccess isRootAgency={true}>
                        <CommentsButton
                            size={ButtonSize.SMALL}
                            deliveryOrderType={DeliveryOrderType.PACKAGE_DELIVERY}
                            deliveryOrderId={packageDeliveryOrder.id}
                            deliveryOrderNumber={packageDeliveryOrder.orderNumber}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </AllowAccess>
                    <RemovePackageDeliveryButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        packageDeliveryOrderId={packageDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default PackageDeliveryRowMedium;
