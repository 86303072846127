import React, {useEffect, useState} from 'react';
import {useStyles} from "./DocumentViewer.style";
import DocumentViewerProperties from "./DocumentViewerProperties";
import {RestClient} from '../../util/RestClient';
import PageLoading from "../PageLoading/PageLoading";
import {useI18nContext} from "../../contexts/I18nContext";

const DocumentViewer: React.FC<DocumentViewerProperties> = ({
                                                                url,
                                                            }: DocumentViewerProperties) => {
    const classes = useStyles();
    const i18n = useI18nContext();
    const [resource, setResource] = useState<any>({});
    const [type, setType] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        setLoading(true);
        RestClient.download(url).then((response) => {
            const data = response.data;
            setType(data?.data.type);
            const objectUrl = window.URL.createObjectURL(data?.data);
            setResource(objectUrl);
        }).finally(() => {
            setLoading(false);
        });
    }, [url]);


    return (
        <div className={classes.document_viewer_container}>
            <PageLoading isLoading={loading} text={i18n.translate("DOWNLOADING_DOCUMENT")}/>
            <embed src={resource}
                   type={type} width="100%" height="100%"/>
        </div>
    )
}
export default DocumentViewer;