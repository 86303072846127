import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
        modal_container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            margin: 0,
            padding: 0,
        },
        view_invoice_container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            height: 'calc(100% - 6px)',
        }
    }))
;

export {useStyles}