import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../MoneyDelivery.style";
import clsx from "clsx";
import MoneyDeliveryRowProperties from "./MoneyDeliveryRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import Currency from '../../../entities/Currency';
import ShowMoneyDeliveryAmounts
    from '../../../shared_components/Info/ShowMoneyDeliveryAmounts/ShowMoneyDeliveryAmounts';
import ViewInvoiceButton from "../../../shared_components/Buttons/ViewInvoiceButton/ViewInvoiceButton";
import RemoveMoneyDeliveryButton
    from '../../../shared_components/Buttons/RemoveMoneyDeliveryButton/RemoveMoneyDeliveryButton';
import ShowContactBankCard from '../../../shared_components/Info/ShowContactBankCard/ShowContactBankCard';
import ShowDeliveryPerson from '../../../shared_components/Info/ShowDeliveryPerson/ShowDeliveryPerson';
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import {useUserContext} from '../../../contexts/UserContext';
import ChooseContactLink from '../../../shared_components/Buttons/ChooseContactLink/ChooseContactLink';
import AllowAccess from '../../../shared_components/Access/AllowAccess/AllowAccess';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import Access from '../../../shared_components/Access/AllowAccess/Access';
import CommentsButton from '../../../shared_components/Buttons/CommentsButton/CommentsButton';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';
import ShowOrderStatus from '../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus';
import UpdateDeliveryOrderStatusButton
    from '../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton';
import Tag from "../../../ui_components/Tag/Tag";
import TagType from "../../../ui_components/Tag/TagType";
import ObservationsButton from '../../../shared_components/Buttons/ObservationsButton/ObservationsButton';
import ProductOrderStatus from '../../../entities/productOrder/ProductOrderStatus';
import ProductOrderStatusHistoryButton from '../../../shared_components/Buttons/ProductOrderStatusHistoryButton/ProductOrderStatusHistoryButton';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import PrintToDeliverButton from '../../../shared_components/Buttons/PrintToDeliverButton/PrintToDeliverButton';
import ServiceName from '../../../entities/ServiceName';

const MoneyDeliveryRowMedium: React.FC<MoneyDeliveryRowProperties> = ({
                                                                          moneyDeliveryOrder,
                                                                          selected,
                                                                          onMoneyDeliveryOrderClick,
                                                                          onMoneyDeliveryOrderSelect,
                                                                          onDeliveryPersonSelect,
                                                                          onContactSelect,
                                                                          onUpdate,
                                                                          filter
                                                                      }) => {


    const classes = useStyles();
    const i18n = useI18nContext();
    const userStore = useUserContext();
    const navigate = useNavigate();

    const onRowMoneyRemittanceClick = () => {
        onMoneyDeliveryOrderClick(moneyDeliveryOrder.id);
    }

    const onMoneyDeliverySelect = () => {
        onMoneyDeliveryOrderSelect(moneyDeliveryOrder.id);
    }

    const handleGoToDetails = (id: string) => {
        navigate(`money-delivery-order-details/${id}`, { replace: true });
    }

    return (
        <div
            className={clsx(classes.money_info_container, userStore.isRootAgency() ? classes.money_info_container_grid_template_big : classes.money_info_container_grid_template_small)}>
            <div>
                <div className={classes.order_number_container}>
                    <Checkbox text={''} onChange={onMoneyDeliverySelect}
                              selected={selected}/>
                    <ShowOrderNumber orderNumber={moneyDeliveryOrder.orderNumber}
                                     className={classes.order_number_text} textToHighlight={filter}/>
                    <Tooltip title="Detalles">
                        <DescriptionIcon style={{ fontSize: 20 }} className={classes.details_icon}
                            onClick={() => handleGoToDetails(moneyDeliveryOrder.id)}/>
                    </Tooltip>
                </div>
                <ShowDate label={i18n.translate("CREATED")} timestamp={moneyDeliveryOrder.createdAt}
                          className={classes.margin_top_4}/>
            </div>
            <div>
                <div className={classes.padding_right_5px}>
                    <ShowText text={moneyDeliveryOrder.clientName} title={'Remitente'} textToHighlight={filter}/>
                    {moneyDeliveryOrder.clientPhone &&
                        <ShowPhoneNumber value={moneyDeliveryOrder.clientPhone} showFlag={false} textToHighlight={filter}/>}
                </div>
                <div className={classes.padding_right_5px}>
                    <ShowText text={moneyDeliveryOrder.contactName} title={'Destinatario'} textToHighlight={filter}/>
                    {moneyDeliveryOrder.contactPhone &&
                        <ShowPhoneNumber value={moneyDeliveryOrder.contactPhone} showFlag={false} textToHighlight={filter}/>}
                    <AllowAccess agencyId={moneyDeliveryOrder.agencyId} any={[Access.UPDATE_MONEY_DELIVERY_CONTACT]}>
                        {!moneyDeliveryOrder.isBankCard && moneyDeliveryOrder.status !== 'DELIVERED' && moneyDeliveryOrder.status !== 'PROCESSING'
                            && moneyDeliveryOrder.status !== 'CANCELED' && moneyDeliveryOrder.status !== 'NOT_DELIVERED'
                            && <ChooseContactLink clientId={moneyDeliveryOrder.clientId} onClick={onRowMoneyRemittanceClick}
                                               onSelect={onContactSelect}/>
                        }
                    </AllowAccess>
                </div>
            </div>
            <div>
                {moneyDeliveryOrder && moneyDeliveryOrder.isBankCard &&
                    <div><small>Envío de tarjeta</small>
                        <div><ShowContactBankCard bank={moneyDeliveryOrder.contactBankCardBank}
                                                  cardNumber={moneyDeliveryOrder.contactBankCardNumber}></ShowContactBankCard>
                        </div>
                    </div>}

                {moneyDeliveryOrder && !moneyDeliveryOrder.isBankCard &&
                    <div><small>Entrega</small>
                        <div><ShowText text={moneyDeliveryOrder.address}
                                       title={moneyDeliveryOrder.geo ? 
                                        moneyDeliveryOrder.geo.name + '. ' + moneyDeliveryOrder.geo.parentInfo :
                                        ''}/>
                        </div>
                    </div>}
            </div>
            <ShowMoneyDeliveryAmounts moneyDeliveryOrder={moneyDeliveryOrder} valueToPay={moneyDeliveryOrder.totalToPay} currencyToPay={Currency.DOLLAR}
                valueToReceive={moneyDeliveryOrder.totalToReceive}
                currencyReceive={moneyDeliveryOrder.currencyToReceive}
                onValuesChange={onUpdate}/>
            <ShowDeliveryPerson
                text={moneyDeliveryOrder.deliveryPersonName}
                showActionLinks={moneyDeliveryOrder.status !== 'DELIVERED' && moneyDeliveryOrder.status !== 'CANCELED' && moneyDeliveryOrder.status !== 'NOT_DELIVERED'}
                orderByGeosIdProximity={moneyDeliveryOrder.geo ? [moneyDeliveryOrder.geo.id] : []}
                onClick={onRowMoneyRemittanceClick}
                onSelect={onDeliveryPersonSelect}
            />
            <div>
                <ShowText text={moneyDeliveryOrder.agencyName} title={'Agencia'}/>
                <ShowText text={moneyDeliveryOrder.userName} title={'Vendedor'} className={classes.margin_top_8}/>
            </div>
            <div className={classes.status_container}>
                {!moneyDeliveryOrder.payed && <Tag text={"SIN PAGAR"} tagType={TagType.DANGER}/>}
                <ShowOrderStatus status={moneyDeliveryOrder.status}/>
                {moneyDeliveryOrder.status !== ProductOrderStatus.DELIVERED &&
                    moneyDeliveryOrder.status !== ProductOrderStatus.CANCELED &&
                    <UpdateDeliveryOrderStatusButton onSave={onUpdate} productOrderId={moneyDeliveryOrder.id}/>}
                {moneyDeliveryOrder.status === ProductOrderStatus.DELIVERED &&
                    <ProductOrderStatusHistoryButton
                        detailsLink
                        productOrderId={moneyDeliveryOrder.id}
                    />}
            </div>
            
            <ExpandoMenu>
                <>
                    <ViewInvoiceButton
                        size={ButtonSize.SMALL}
                        productOrderId={moneyDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <PrintToDeliverButton
                        size={ButtonSize.SMALL}
                        productOrderIds={[moneyDeliveryOrder.id]}
                        service={ServiceName.MONEY_DELIVERY}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ProductOrderStatusHistoryButton
                        size={ButtonSize.SMALL}
                        productOrderId={moneyDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ObservationsButton
                        size={ButtonSize.SMALL}
                        deliveryOrderId={moneyDeliveryOrder.id}
                        deliveryOrderNumber={moneyDeliveryOrder.orderNumber}
                        deliveryOrderObservations={moneyDeliveryOrder.observations}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <AllowAccess isRootAgency={true}>
                        <CommentsButton
                            size={ButtonSize.SMALL}
                            deliveryOrderId={moneyDeliveryOrder.id}
                            deliveryOrderNumber={moneyDeliveryOrder.orderNumber}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </AllowAccess>
                    <RemoveMoneyDeliveryButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        moneyDeliveryOrderId={moneyDeliveryOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default MoneyDeliveryRowMedium;
