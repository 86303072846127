import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import {useStyles} from "../ShopOrder.style";
import clsx from "clsx";
import ShopOrderRowProperties from "./ShopOrderRowProperties";
import ShowDate from "../../../shared_components/Info/ShowDate/ShowDate";
import ShowText from '../../../shared_components/Info/ShowText/ShowText';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import ButtonType from '../../../ui_components/Button/ButtonType';
import ViewInvoiceButton from "../../../shared_components/Buttons/ViewInvoiceButton/ViewInvoiceButton";
import ShowOrderNumber from '../../../shared_components/Info/ShowOrderNumber/ShowOrderNumber';
import {useUserContext} from '../../../contexts/UserContext';
import AllowAccess from '../../../shared_components/Access/AllowAccess/AllowAccess';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import CommentsButton from '../../../shared_components/Buttons/CommentsButton/CommentsButton';
import Checkbox from '../../../ui_components/Checkbox/Checkbox';
import ShowPhoneNumber from '../../../shared_components/Info/ShowPhoneNumber/ShowPhoneNumber';
import ShowOrderStatus from '../../../shared_components/Info/ShowOrderStatus/ShowOrderStatus';
import UpdateDeliveryOrderStatusButton from '../../../shared_components/Buttons/UpdateDeliveryOrderStatusButton/UpdateDeliveryOrderStatusButton';
import DeliveryOrderType from '../../../model/DeliveryOrderType';
import ProductOrderStatus from '../../../entities/productOrder/ProductOrderStatus';
import ProductOrderStatusHistoryButton from '../../../shared_components/Buttons/ProductOrderStatusHistoryButton/ProductOrderStatusHistoryButton';
import { CurrencyModule } from '../../../util/Currency';
import Currency from '../../../entities/Currency';
import ShowShopOrderProducts from '../../../shared_components/Info/ShowShopOrderProducts/ShowShopOrderProducts';
import RemoveShopOrderButton from '../../../shared_components/Buttons/RemoveShopOrderButton/RemoveShopOrderButton';

const ShopOrderRowMedium: React.FC<ShopOrderRowProperties> = ({
                                                                          shopOrder,
                                                                          selectedDeliveryOrderIds,
                                                                          onShopOrderSelect,
                                                                          onUpdate,
                                                                          filter
                                                                      }) => {


    const classes = useStyles();
    const i18n = useI18nContext();
    const userStore = useUserContext();

    const onInternalShopOrderSelect = () => {
        onShopOrderSelect(shopOrder.id);
    }

    return (
        <div
            className={clsx(classes.article_info_container, userStore.isRootAgency() ? classes.article_info_container_grid_template_big : classes.article_info_container_grid_template_small)}>
            <div>
                <div className={classes.order_number_container}>
                    <Checkbox text={''} onChange={onInternalShopOrderSelect}
                              selected={selectedDeliveryOrderIds.findIndex(t => t === shopOrder.id) >= 0}/>
                    <ShowOrderNumber orderNumber={shopOrder.orderNumber}
                                     className={classes.order_number_text} textToHighlight={filter}/>
                </div>
                <ShowDate label={i18n.translate("CREATED")} timestamp={shopOrder.createdAt}
                          className={classes.margin_top_4}/>
            </div>
            <div>
                <div className={classes.padding_right_5px}>
                    <ShowText text={shopOrder.clientName} title={'Cliente'} textToHighlight={filter}/>
                    {shopOrder.clientPhone &&
                        <ShowPhoneNumber value={shopOrder.clientPhone} showFlag={false} textToHighlight={filter}/>}
                </div>
            </div>
            <div>
                <ShowShopOrderProducts products={shopOrder.products}/>
            </div>
            <div>
                <div className={classes.title}>{`${i18n.translate("TOTAL_TO_PAY")}:`}</div>
                <div className={classes.value}>{CurrencyModule.format(shopOrder.totalToPay, Currency.DOLLAR)}</div>
            </div>
            <div>
                <ShowText text={shopOrder.agencyName} title={'Agencia'}/>
                <ShowText text={shopOrder.userName} title={'Vendedor'} className={classes.margin_top_8}/>
            </div>
            <div className={classes.status_container}>
                <ShowOrderStatus status={shopOrder.status}/>
                {shopOrder.status !== ProductOrderStatus.DELIVERED &&
                    shopOrder.status !== ProductOrderStatus.CANCELED &&
                    <UpdateDeliveryOrderStatusButton onSave={onUpdate} productOrderId={shopOrder.id} deliveryOrderType={DeliveryOrderType.MISCELLANY_ORDER}/>}
                {shopOrder.status === ProductOrderStatus.DELIVERED &&
                    <ProductOrderStatusHistoryButton
                        detailsLink
                        productOrderId={shopOrder.id}
                    />}
            </div>
            
            <ExpandoMenu>
                <>
                    <ViewInvoiceButton
                        size={ButtonSize.SMALL}
                        productOrderId={shopOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <ProductOrderStatusHistoryButton
                        size={ButtonSize.SMALL}
                        productOrderId={shopOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                    <AllowAccess isRootAgency={true}>
                        <CommentsButton
                            size={ButtonSize.SMALL}
                            deliveryOrderId={shopOrder.id}
                            deliveryOrderNumber={shopOrder.orderNumber}
                            showText
                            type={ButtonType.SECONDARY}
                        />
                    </AllowAccess>
                    <RemoveShopOrderButton
                        size={ButtonSize.SMALL}
                        onSave={onUpdate}
                        shopOrderId={shopOrder.id}
                        showText
                        type={ButtonType.SECONDARY}
                    />
                </>
            </ExpandoMenu>
        </div>
    )
}

export default ShopOrderRowMedium;
