import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./PackageDelivery.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {useCommonContext} from "../../contexts/CommonContext";
import PackageDeliveryStore from './PackageDeliveryStore';
import GeoSelect from "../../shared_components/Selects/GeoSelect/GeoSelect";
import DeliveryPerson from '../../entities/DeliveryPerson';
import ButtonType from "../../ui_components/Button/ButtonType";
import NewPackageDeliveryButton from "../../shared_components/Buttons/NewPackageDeliveryButton/NewPackageDeliveryButton";
import PackageDeliveryRow from './PackageDeliveryRow/PackageDeliveryRow';
import DeliveryPersonSelect from '../../shared_components/Selects/DeliveryPersonSelect/DeliveryPersonSelect';
import RemittanceStatusSelect from '../../shared_components/Selects/RemittanceStatusSelect/RemittanceStatusSelect';
import DateTimeRangeSelect from '../../ui_components/DateTimeRageSelect/DateTimeRangeSelect';
import AllowAccess from "../../shared_components/Access/AllowAccess/AllowAccess";
import Access from "../../shared_components/Access/AllowAccess/Access";
import ClientContact from '../../entities/ClientContact';
import AgencySelect from "../../shared_components/Selects/AgencySelect/AgencySelect";
import Agency from "../../entities/Agency";
import PrintToDeliverButton from '../../shared_components/Buttons/PrintToDeliverButton/PrintToDeliverButton';
import Checkbox from '../../ui_components/Checkbox/Checkbox';
import FilterInput from '../../shared_components/Inputs/FilterInput/FilterInput';
import { useUserContext } from '../../contexts/UserContext';
import ShippingInput from "../../shared_components/Inputs/ShippingInput/ShippingInput";
import AssignShippingIdButton from "../../shared_components/Buttons/AssignShippingIdButton/AssignShippingIdButton";
import ShowDeliveryPersonMulti from '../../shared_components/Info/ShowDeliveryPersonMulti/ShowDeliveryPersonMulti';
import PackageDeliveryOverview from '../../shared_components/Widget/PackageDeliveryOverview/PackageDeliveryOverview';
import ShippingStatusSelect from "../../shared_components/Selects/ShippingStatusSelect/ShippingStatusSelect";
import Geo from '../../entities/Geo';
import ButtonSize from '../../ui_components/Button/ButtonSize';
import ServiceName from '../../entities/ServiceName';

const PackageDelivery: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    
    const classes = useStyles();
    const userStore = useUserContext();

    const store = new PackageDeliveryStore(commonStore, i18n);

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line
    }, [] );

    //FILTERS
    const setFilter = (filter?:string)=>{ store.setFilter(filter) }
    const setShippingIdFilter = (shippingIdFilter?:string) => { store.setShippingIdFilter(shippingIdFilter) }
    const setShippingAssignedFilter = (shipmentAssignedFilter?:string) => { store.setShipmentAssignedFilter(shipmentAssignedFilter) }
    const setStatusFilter = (statusFilter?: string[]) => { store.setStatusFilter(statusFilter) }
    const setDeliveryPersonFilter = (deliveryPersonFilter?: DeliveryPerson) => { store.setDeliveryPersonFilter(deliveryPersonFilter)}
    const onDateChange = (from: number | undefined, to: number | undefined) => {
      store.setFromFilter(from)
      store.setToFilter(to)
    }
    const onAgencyChange = (newAgency?: Agency) =>{ store.setAgencyFilter(newAgency) }
    const onGeoChange = (geo?: Geo) =>{ store.setGeoFilter(geo) }


    const onPackageDeliveryClick = (packageDeliveryId: string) => {
        store.selectedPackageDeliveryId = packageDeliveryId;
    }

    const onDeliveryPersonSelect = (deliveryPerson?: DeliveryPerson) => {
        if (deliveryPerson != null) {
            store.setPackageDeliveryDeliveryPerson([store.selectedPackageDeliveryId], deliveryPerson);
        } else {
            store.unsetPackageDeliveryDeliveryPerson();
        }
    }

    const onDeliveryPersonSelectForSeveralPackageDelivery = (deliveryPerson?: DeliveryPerson) => {
        if (deliveryPerson != null) {
            store.setPackageDeliveryDeliveryPerson(store.selectedPackageDeliveryIds, deliveryPerson);
            store.selectedPackageDeliveryIds = [];
        }
    }

    const onContactSelect = (clientContact: ClientContact) => {
        store.updatePackageDeliveryContact(clientContact);
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }


    const onUpdate = async () => {
        await store.getElements();
        store.selectedPackageDeliveryIds = [];
    }

    const onPackageDeliveryOrderSelect = (packageDeliveryId: string, packageDeliveryOrderNumber:string) => {
        const index = store.selectedPackageDeliveryIds.findIndex(t => t === packageDeliveryId);
        store.selectedPackageDeliveryIds = index >= 0 ?
            store.selectedPackageDeliveryIds.filter(t => t !== packageDeliveryId) :
            [...store.selectedPackageDeliveryIds, packageDeliveryId];
        store.selectedPackageDeliveryOrderNumbers = index >= 0 ?
        store.selectedPackageDeliveryOrderNumbers.filter(t => t !== packageDeliveryOrderNumber) :
        [...store.selectedPackageDeliveryOrderNumbers, packageDeliveryOrderNumber];
    }

    const onPackageDeliveryOrderToggleSelectAll = (value: boolean) => {
        store.selectedPackageDeliveryIds = value ? store.packageDeliverys.map(t => t.id) : [];
        store.selectedPackageDeliveryOrderNumbers = value ? store.packageDeliverys.map(t => t.orderNumber) : [];
    }

    return (
      <Observer>
        {() => (
          <div className={classes.money_container}>
            <Box
              className={classes.filter_container}
              width={Width.FULL_WIDTH}
              justifyContent={Alignment.SPACE_BETWEEN}>
                <FilterInput value={store.filter}
                    placeholder={i18n.translate("PACKAGE_DELIVERY_ORDER_FILTER_PLACEHOLDER")}
                    onChange={setFilter}/>
                <DateTimeRangeSelect
                    selectedFrom={store.fromFilter} 
                    selectedTo={store.toFilter}
                    label={i18n.translate("DATES")}
                    onChange={onDateChange}
              />
              <AllowAccess any={[Access.SEND_ORDERS_TO_AGENCIES]}>
                <AgencySelect
                  selected={store.agencyFilter}
                  onChange={onAgencyChange}
                />
                <AllowAccess any={[Access.LIST_DELIVERY_PERSON]}>
                <DeliveryPersonSelect
                  onChange={setDeliveryPersonFilter}
                  selected={store.deliveryPersonFilter}
                />
              </AllowAccess>
                <RemittanceStatusSelect
                  selectedStatus={store.statusFilter}
                  onChange={setStatusFilter}
                />
                <GeoSelect
                  selected={store.geoFilter}
                  onChange={onGeoChange}
                />
                <ShippingStatusSelect
                  onChange={setShippingAssignedFilter}
                  selected={store.shipmentAssignedFilter}
                />
              </AllowAccess>
              <ShippingInput value={store.shippingIdFilter}
                placeholder={i18n.translate("PACKAGE_DELIVERY_SHIPPING_FILTER_PLACEHOLDER")}
                onChange={setShippingIdFilter}/>
              <div className={classes.filter_buttons_container}>
                <Button
                  onClick={() => {
                    store.search();
                  }}
                  type={ButtonType.PRIMARY}
                  className={classes.search_btn}
                  text={i18n.translate("SEARCH")}/>

                <Button
                  onClick={() => {
                    store.clearFilter();
                  }}
                  className={classes.search_btn}
                  text={i18n.translate("CLEAN")}/>

              </div>
            </Box>
            <div className={classes.money_table}>
              <Box
                className={classes.tools_container}
                width={Width.FULL_WIDTH}
                justifyContent={Alignment.END}>

                <Checkbox
                  text={""}
                  selected={store.selectedPackageDeliveryIds.length === store.packageDeliverys.length}
                  onChange={onPackageDeliveryOrderToggleSelectAll}
                  className={classes.select_all_checkbox} />

                  <AssignShippingIdButton
                      multiOrders={true}
                      deliveryOrderIds={store.selectedPackageDeliveryIds}
                      deliveryOrderNumbers={store.selectedPackageDeliveryOrderNumbers}
                      className={classes.border_button}
                      onSelect={onDeliveryPersonSelectForSeveralPackageDelivery}
                      type={ButtonType.SECONDARY}
                      onUpdate={onUpdate}
                      disabled={store.haveNotSelected}/>

                  <ShowDeliveryPersonMulti
                    onUpdate={onUpdate}
                    multiOrders={true} 
                    deliveryOrderIds={store.selectedPackageDeliveryIds}
                    deliveryOrderNumbers={store.selectedPackageDeliveryOrderNumbers}
                    orderByGeosIdProximity={store.packageDeliverys.filter(t => store.selectedPackageDeliveryIds.indexOf(t.id) >= 0).map(t => t.geo.id)}
                    text={""}
                    className={classes.border_button}
                    disabled={store.selectedPackageDeliveryIds.length === 0}
                    type={ButtonType.SECONDARY}/>

                  <PrintToDeliverButton
                      size={ButtonSize.SMALL}
                      productOrderIds={store.selectedPackageDeliveryIds}
                      disabled={store.selectedPackageDeliveryIds.length === 0}
                      service={ServiceName.PACKAGE_DELIVERY}
                      showText
                      type={ButtonType.SECONDARY}
                    />

                  {userStore.hasAccess(Access.CREATE_EDIT_PACKAGE_DELIVERY_ORDER) &&
                    <NewPackageDeliveryButton type={ButtonType.SECONDARY} onSuccessCreate={onUpdate}/>
                  }
              </Box>
              <PackageDeliveryOverview
                            from={store.fromFilter}
                            to={store.toFilter}
                            deliveryPersonId={store.deliveryPersonFilter?.id}
                            agencyId={store.agencyFilter?.id}
                            status={store.statusFilter}
                            filter={store.filter}
                            geoId={store.geoFilter?.id}
                            shippingId={store.shippingIdFilter}
                            includeChildren
                        />
              {!store.isEmpty && (
                <>
                  <ShowPagination
                    onChange={onPaginationChange}
                    elementLabel={i18n.translate("PACKAGE_DELIVERY")}
                    pagination={store.pagination}/>
                  <div className={classes.option_buttons_container}></div>
                  <div className={classes.elements_container}>
                    {store.packageDeliverys.map((packageDelivery, i) => (
                      <PackageDeliveryRow
                        onPackageDeliveryOrderClick={onPackageDeliveryClick}
                        onPackageDeliveryOrderSelect={onPackageDeliveryOrderSelect}
                        onDeliveryPersonSelect={onDeliveryPersonSelect}
                        onContactSelect={onContactSelect}
                        packageDeliveryOrder={packageDelivery}
                        key={i}
                        selectedDeliveryOrderIds={store.selectedPackageDeliveryIds}
                        onUpdate={onUpdate}
                        filter={store.filter}
                        shippingId={store.shippingIdFilter}/>
                    ))}
                  </div>
                  <ShowPagination
                    onChange={onPaginationChange}
                    elementLabel={i18n.translate("PACKAGE_DELIVERY")}
                    pagination={store.pagination}/>
                </>
              )}
            </div>

            {!store.isEmpty}
            {store.isEmpty && (
              <MessageCard
                icon={AttachMoneyIcon}
                title={i18n.translate("NO_PACKAGE_DELIVERY_TITLE")}
                subtitle={i18n.translate("NO_PACKAGE_DELIVERY_SUBTITLE")}
              />
            )}
          </div>
        )}
      </Observer>
    );
}

export default PackageDelivery;
