import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    document_viewer_container: {
        width: '100%',
        height: '100%',
        minWidth: 850,
        position: "relative"
    },
}));

export {useStyles}